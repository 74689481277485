import React, { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button, Layout, Menu, MenuProps } from "antd";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PicLeftOutlined,
} from "@ant-design/icons";
import {
  RouteNames,
  PersistentStorageContext,
  IconDashboard,
  IconDefinyProWallet,
  IconSidebarPlayers,
  IconBonusControl,
  IconMyReports,
  IconMessenger,
  IconSystemUsers,
  IconTranslations,
  IconGamesControl,
  IconSettingsControl,
  useBonusConfig,
  usePermissions,
  useAppConfig,
  WalletTabTypes,
} from "@finbackoffice/backoffice-core-next";
import { usePathname } from "next/navigation";
import Link from "next/link";
import { AccountingTabTypes } from "@/screens/definy/accounting/Accounting";
import "./sidebar.sass";

const { Sider } = Layout;

const ICON_STYLE = { fontSize: "19px" };

type MenuItem = Required<MenuProps>["items"][number];

export const Sidebar = ({ brand }: { brand?: string }) => {
  const pathname = usePathname();
  const { i18n } = useTranslation();
  const {
    showCashier,
    showDeposit,
    showWithdrawal,
    showFreeSpinCtrl,
    showReels,
    showSportBonusCtrl,
  } = useAppConfig("SIDEBAR_CONFIG");
  const version = useAppConfig("VERSION");
  const [t] = useTranslation();
  const { availableMenus } = usePermissions();
  const { getSettings, setSettings } = useContext(PersistentStorageContext);
  const { sidebarCollapsed } = getSettings();
  const { showCashbackReports, showReferralReports } = useBonusConfig();
  const [, _lang, categoryRoute, pageRoute] = pathname?.split("/") ?? [];

  const handleButtonClick = useCallback(() => {
    setSettings({ sidebarCollapsed: !sidebarCollapsed });
  }, [setSettings, sidebarCollapsed]);

  function getMenuItem(
    label: React.ReactNode,
    key?: React.Key | null,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: string,
    className = "menu-item",
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type,
      className,
    } as MenuItem;
  }

  const getLink = useCallback(
    (href: string, label: string) => {
      return <Link href={`/${i18n.language}${href}`}>{t(label)}</Link>;
    },
    [i18n.language, t],
  );

  const menuItems = useMemo(() => {
    const reportMenuRoutes = [
      RouteNames.BETS,
      RouteNames.CASINO_BETS,
      RouteNames.PLAYERS_ACTIVITY,
    ];
    const bonusReportsMenus = [];

    const definyMenus = [];

    if (showCashier) {
      definyMenus.push({
        [RouteNames.CASHIER]: getMenuItem(
          getLink(
            `/${RouteNames.DEFINY}/${RouteNames.CASHIER}`,
            "cashier.heading",
          ),
          RouteNames.CASHIER,
        ),
      });
    }

    if (showDeposit) {
      definyMenus.push({
        [RouteNames.CASHIER_DEPOSITS]: getMenuItem(
          getLink(
            `/${RouteNames.DEFINY}/${RouteNames.CASHIER_DEPOSITS}`,
            "cashier.heading-deposit",
          ),
          RouteNames.CASHIER_DEPOSITS,
        ),
      });
    }

    if (showWithdrawal) {
      definyMenus.push({
        [RouteNames.CASHIER_WITHDRAWALS]: getMenuItem(
          getLink(
            `/${RouteNames.DEFINY}/${RouteNames.CASHIER_WITHDRAWALS}`,
            "cashier.heading-withdrawal",
          ),
          RouteNames.CASHIER_WITHDRAWALS,
        ),
      });
    }

    if (showReferralReports) {
      reportMenuRoutes.push(RouteNames.REFERRALS);
      bonusReportsMenus.push({
        [RouteNames.REFERRALS]: getMenuItem(
          getLink(
            `/${RouteNames.REPORTS}/${RouteNames.REFERRALS}`,
            "sidebar.referral-reports",
          ),
          RouteNames.REFERRALS,
        ),
      });
    }

    if (showCashbackReports) {
      reportMenuRoutes.push(RouteNames.CASHBACK);
      bonusReportsMenus.push({
        [RouteNames.CASHBACK]: getMenuItem(
          getLink(
            `/${RouteNames.REPORTS}/${RouteNames.CASHBACK}`,
            "sidebar.cashback-reports",
          ),
          RouteNames.CASHBACK,
        ),
      });
    }

    const reportMenus = [
      {
        [RouteNames.BETS]: getMenuItem(
          getLink(`/${RouteNames.REPORTS}/${RouteNames.BETS}`, "sidebar.bets"),
          RouteNames.BETS,
        ),
      },
      {
        [RouteNames.CASINO_BETS]: getMenuItem(
          getLink(
            `/${RouteNames.REPORTS}/${RouteNames.CASINO_BETS}`,
            "sidebar.casino-bets",
          ),
          RouteNames.CASINO_BETS,
        ),
      },
      ...bonusReportsMenus,
      {
        [RouteNames.PLAYERS_ACTIVITY]: getMenuItem(
          getLink(
            `/${RouteNames.REPORTS}/${RouteNames.PLAYERS_ACTIVITY}`,
            "sidebar.players-activity",
          ),
          RouteNames.PLAYERS_ACTIVITY,
        ),
      },
    ];

    const walletControlMenus = [
      ...definyMenus,
      {
        [RouteNames.WALLET]: getMenuItem(
          getLink(
            `/${RouteNames.DEFINY}/${RouteNames.WALLET}/${WalletTabTypes.WALLETS}`,
            "sidebar.wallet",
          ),
          RouteNames.WALLET,
        ),
      },
      {
        [RouteNames.ACCOUNTING]: getMenuItem(
          getLink(
            `/${RouteNames.DEFINY}/${RouteNames.ACCOUNTING}/${AccountingTabTypes.BALANCES}`,
            "sidebar.accounting",
          ),
          RouteNames.ACCOUNTING,
        ),
      },
      {
        [RouteNames.TRANSACTIONS]: getMenuItem(
          getLink(
            `/${RouteNames.DEFINY}/${RouteNames.TRANSACTIONS}/${RouteNames.WALLET_TRANSACTIONS}`,
            "sidebar.transactions",
          ),
          RouteNames.TRANSACTIONS,
        ),
      },
    ];

    const playerControlMenus = [
      {
        [RouteNames.PLAYERS]: getMenuItem(
          getLink(
            `/${RouteNames.PLAYERS_CONTROL}/${RouteNames.PLAYERS}`,
            "players.heading",
          ),
          RouteNames.PLAYERS,
        ),
      },
      {
        [RouteNames.CREATE_PLAYER]: getMenuItem(
          getLink(
            `/${RouteNames.PLAYERS_CONTROL}/${RouteNames.CREATE_PLAYER}`,
            "sidebar.create-player",
          ),
          RouteNames.CREATE_PLAYER,
        ),
      },
      {
        [RouteNames.USER_TAGS]: getMenuItem(
          getLink(
            `/${RouteNames.PLAYERS_CONTROL}/${RouteNames.USER_TAGS}`,
            "sidebar.user-tags",
          ),
          RouteNames.USER_TAGS,
        ),
      },
      {
        [RouteNames.NOTIFICATIONS]: getMenuItem(
          getLink(
            `/${RouteNames.PLAYERS_CONTROL}/${RouteNames.NOTIFICATIONS}`,
            "sidebar.notifications",
          ),
          RouteNames.NOTIFICATIONS,
        ),
      },
    ];

    const cmsMenus: Partial<Record<RouteNames, MenuItem>>[] = [
      {
        [RouteNames.BANNERS_CONTROL]: getMenuItem(
          getLink(
            `/${RouteNames.CMS}/${RouteNames.BANNERS_CONTROL}/${RouteNames.POPUP}`,
            "sidebar.banners-control",
          ),
          RouteNames.BANNERS_CONTROL,
        ),
      },
      {
        [RouteNames.TOP_LEAGUES]: getMenuItem(
          getLink(
            `/${RouteNames.CMS}/${RouteNames.TOP_LEAGUES}`,
            "sidebar.top-leagues",
          ),
          RouteNames.TOP_LEAGUES,
        ),
      },
    ];

    if (showReels) {
      cmsMenus.push({
        [RouteNames.REELS]: getMenuItem(
          getLink(`/${RouteNames.CMS}/${RouteNames.REELS}`, "sidebar.reels"),
          `${RouteNames.BONUS_CONTROL}_${RouteNames.REELS}`,
          null,
          undefined,
          undefined,
          pageRoute === RouteNames.REELS ? "ant-menu-item-selected" : "",
        ),
      });
    }

    const systemUserMenus = [
      {
        [RouteNames.USERS]: getMenuItem(
          getLink(
            `/${RouteNames.SYSTEM_USERS}/${RouteNames.USERS}`,
            "sidebar.system-users",
          ),
          RouteNames.USERS,
        ),
      },
      {
        [RouteNames.CREATE_USER]: getMenuItem(
          getLink(
            `/${RouteNames.SYSTEM_USERS}/${RouteNames.CREATE_USER}`,
            "sidebar.create-user",
          ),
          RouteNames.CREATE_USER,
        ),
      },
    ];

    const bonusControlMenus = [
      {
        [RouteNames.AGENT_SETTINGS]: getMenuItem(
          getLink(
            `/${RouteNames.BONUS_CONTROL}/${RouteNames.AGENT_SETTINGS}`,
            "sidebar.agent-settings",
          ),
          RouteNames.AGENT_SETTINGS,
        ),
      },
      {
        [RouteNames.AGENT_REPORT]: getMenuItem(
          getLink(
            `/${RouteNames.BONUS_CONTROL}/${RouteNames.AGENT_REPORT}`,
            "sidebar.agent-report",
          ),
          RouteNames.AGENT_REPORT,
        ),
      },
      {
        [RouteNames.AGENT_CASHBACK]: getMenuItem(
          getLink(
            `/${RouteNames.BONUS_CONTROL}/${RouteNames.AGENT_CASHBACK}`,
            "sidebar.agent-cashback",
          ),
          RouteNames.AGENT_CASHBACK,
        ),
      },
      {
        [RouteNames.CASHBACK]: getMenuItem(
          getLink(
            `/${RouteNames.BONUS_CONTROL}/${RouteNames.CASHBACK}`,
            "sidebar.cashback-reports",
          ),
          `${RouteNames.BONUS_CONTROL}_${RouteNames.CASHBACK}`,
          null,
          undefined,
          undefined,
          pageRoute === RouteNames.CASHBACK ? "ant-menu-item-selected" : "",
        ),
      },
      !!showFreeSpinCtrl && {
        [RouteNames.FREE_SPIN_BONUS]: getMenuItem(
          getLink(
            `/${RouteNames.BONUS_CONTROL}/${RouteNames.FREE_SPIN_BONUS}/${RouteNames.ACTIVE}`,
            "sidebar.free-spin-bonus",
          ),
          `${RouteNames.BONUS_CONTROL}_${RouteNames.FREE_SPIN_BONUS}`,
          null,
          undefined,
          undefined,
          pageRoute === RouteNames.FREE_SPIN_BONUS
            ? "ant-menu-item-selected"
            : "",
        ),
      },
      {
        [RouteNames.REFERRALS]: getMenuItem(
          getLink(
            `/${RouteNames.BONUS_CONTROL}/${RouteNames.REFERRALS}`,
            "sidebar.referral-reports",
          ),
          `${RouteNames.BONUS_CONTROL}_${RouteNames.REFERRALS}`,
          null,
          undefined,
          undefined,
          pageRoute === RouteNames.REFERRALS ? "ant-menu-item-selected" : "",
        ),
      },
      {
        [RouteNames.REFERRAL_CASHBACK]: getMenuItem(
          getLink(
            `/${RouteNames.BONUS_CONTROL}/${RouteNames.REFERRAL_CASHBACK}`,
            "sidebar.referral_cashback",
          ),
          RouteNames.REFERRAL_CASHBACK,
        ),
      },
      !!showSportBonusCtrl && {
        [RouteNames.SPORT_BONUS_CONTROL]: getMenuItem(
          getLink(
            `/${RouteNames.BONUS_CONTROL}/${RouteNames.SPORT_BONUS_CONTROL}`,
            "sidebar.sport-bonus-control",
          ),
          RouteNames.SPORT_BONUS_CONTROL,
        ),
      },
      !!showSportBonusCtrl && {
        [RouteNames.SPORT_BONUS_REPORT]: getMenuItem(
          getLink(
            `/${RouteNames.BONUS_CONTROL}/${RouteNames.SPORT_BONUS_REPORT}`,
            "sidebar.sport-bonus-report",
          ),
          RouteNames.SPORT_BONUS_REPORT,
        ),
      },
    ];

    const translationMenus = [
      {
        [RouteNames.SPORTS_DATA]: getMenuItem(
          getLink(
            `/${RouteNames.TRANSLATIONS}/${RouteNames.SPORTS_DATA}`,
            "sidebar.sports-data",
          ),
          RouteNames.SPORTS_DATA,
        ),
      },
      {
        [RouteNames.INTERFACES]: getMenuItem(
          getLink(
            `/${RouteNames.TRANSLATIONS}/${RouteNames.INTERFACES}`,
            "sidebar.interfaces",
          ),
          RouteNames.INTERFACES,
        ),
      },
    ];

    const gamesControlMenus = [
      {
        [RouteNames.SPORT]: getMenuItem(
          getLink(
            `/${RouteNames.GAMES_CONTROL}/${RouteNames.SPORT}`,
            "sidebar.sport-control",
          ),
          RouteNames.SPORT,
        ),
      },
      {
        [RouteNames.CASINO_CONTROL]: getMenuItem(
          getLink(
            `/${RouteNames.GAMES_CONTROL}/${RouteNames.CASINO_CONTROL}/${RouteNames.CASINO}`,
            "sidebar.casino-control",
          ),
          RouteNames.CASINO_CONTROL,
        ),
      },
      {
        [RouteNames.CASINO_BETS_CONTROL]: getMenuItem(
          getLink(
            `/${RouteNames.GAMES_CONTROL}/${RouteNames.CASINO_BETS_CONTROL}`,
            "sidebar.casino-bets-ctrl",
          ),
          RouteNames.CASINO_BETS_CONTROL,
        ),
      },
      {
        [RouteNames.BETS_PANEL]: getMenuItem(
          getLink(
            `/${RouteNames.GAMES_CONTROL}/${RouteNames.BETS_PANEL}`,
            "sidebar.bets-control",
          ),
          RouteNames.BETS_PANEL,
        ),
      },
      {
        [RouteNames.REJECTED_BETS]: getMenuItem(
          getLink(
            `/${RouteNames.GAMES_CONTROL}/${RouteNames.REJECTED_BETS}`,
            "sidebar.rejected-bets",
          ),
          RouteNames.REJECTED_BETS,
        ),
      },
      {
        [RouteNames.MTS_BETS]: getMenuItem(
          getLink(
            `/${RouteNames.GAMES_CONTROL}/${RouteNames.MTS_BETS}`,
            "sidebar.mts-bets",
          ),
          RouteNames.MTS_BETS,
        ),
      },
    ];

    const adminControlMenus = [
      {
        [RouteNames.PARTNER_CASINO]: getMenuItem(
          getLink(
            `/${RouteNames.ADMIN_CONTROL}/${RouteNames.PARTNER_CASINO}`,
            "sidebar.partner-casino",
          ),
          RouteNames.PARTNER_CASINO,
        ),
      },
      {
        [RouteNames.REGISTRATION_FORM]: getMenuItem(
          getLink(
            `/${RouteNames.ADMIN_CONTROL}/${RouteNames.REGISTRATION_FORM}`,
            "sidebar.registration-form",
          ),
          RouteNames.REGISTRATION_FORM,
        ),
      },
      {
        [RouteNames.FREE_SPIN_BONUS]: getMenuItem(
          getLink(
            `/${RouteNames.ADMIN_CONTROL}/${RouteNames.FREE_SPIN_BONUS}/${RouteNames.ACTIVE}`,
            "sidebar.free-spin-bonus",
          ),
          RouteNames.FREE_SPIN_BONUS,
        ),
      },
      {
        [RouteNames.REELS]: getMenuItem(
          getLink(
            `/${RouteNames.ADMIN_CONTROL}/${RouteNames.REELS}`,
            "sidebar.reels",
          ),
          RouteNames.REELS,
        ),
      },
      {
        [RouteNames.SPORT_BONUS_CONTROL]: getMenuItem(
          getLink(
            `/${RouteNames.ADMIN_CONTROL}/${RouteNames.SPORT_BONUS_CONTROL}`,
            "sidebar.sport-bonus-control",
          ),
          RouteNames.SPORT_BONUS_CONTROL,
        ),
      },
      {
        [RouteNames.SPORT_BONUS_REPORT]: getMenuItem(
          getLink(
            `/${RouteNames.ADMIN_CONTROL}/${RouteNames.SPORT_BONUS_REPORT}`,
            "sidebar.sport-bonus-report",
          ),
          RouteNames.SPORT_BONUS_REPORT,
        ),
      },
      {
        [RouteNames.CASHIER_DEPOSITS]: getMenuItem(
          getLink(
            `/${RouteNames.DEFINY}/${RouteNames.CASHIER_DEPOSITS}`,
            "cashier.heading-deposit",
          ),
          `${RouteNames.ADMIN_CONTROL}_${RouteNames.CASHIER_DEPOSITS}`,
          null,
          undefined,
          undefined,
          pageRoute === RouteNames.CASHIER_DEPOSITS
            ? "ant-menu-item-selected"
            : "",
        ),
      },
      {
        [RouteNames.CASHIER_WITHDRAWALS]: getMenuItem(
          getLink(
            `/${RouteNames.DEFINY}/${RouteNames.CASHIER_WITHDRAWALS}`,
            "cashier.heading-withdrawal",
          ),
          `${RouteNames.ADMIN_CONTROL}_${RouteNames.CASHIER_WITHDRAWALS}`,
          null,
          undefined,
          undefined,
          pageRoute === RouteNames.CASHIER_WITHDRAWALS
            ? "ant-menu-item-selected"
            : "",
        ),
      },
      {
        [RouteNames.SITE_CONFIGS]: getMenuItem(
          getLink(
            `/${RouteNames.ADMIN_CONTROL}/${RouteNames.SITE_CONFIGS}`,
            "sidebar.site-configs",
          ),
          RouteNames.SITE_CONFIGS,
        ),
      },
    ];

    const getChildrenMenuMap: Record<string, any> = {
      [RouteNames.DASHBOARD]: null,
      [RouteNames.REPORTS]: reportMenus,
      [RouteNames.DEFINY]: walletControlMenus,
      [RouteNames.PLAYERS_CONTROL]: playerControlMenus,
      [RouteNames.CMS]: cmsMenus,
      [RouteNames.MESSENGER]: null,
      [RouteNames.SYSTEM_USERS]: systemUserMenus,
      [RouteNames.BONUS_CONTROL]: bonusControlMenus,
      [RouteNames.TRANSLATIONS]: translationMenus,
      [RouteNames.GAMES_CONTROL]: gamesControlMenus,
      [RouteNames.ADMIN_CONTROL]: adminControlMenus,
    };

    const getItems = (key: string, excludedMenus?: RouteNames[]) => {
      let filteredChildMenus;
      const childMenus = getChildrenMenuMap[key] ?? [];
      if (excludedMenus && childMenus.length > 0) {
        filteredChildMenus = childMenus
          .filter(
            (c: any) =>
              !excludedMenus.includes(Object.keys(c)[0] as RouteNames),
          )
          .map((c: any) => Object.values(c)[0]);
      } else {
        filteredChildMenus = childMenus.map((c: any) => Object.values(c)[0]);
      }
      const items: Record<string, any> = {
        [RouteNames.DASHBOARD]: getMenuItem(
          getLink(`/${RouteNames.DASHBOARD}`, "sidebar.dashboard"),
          RouteNames.DASHBOARD,
          <span>
            <IconDashboard />
          </span>,
        ),
        [RouteNames.REPORTS]: sidebarCollapsed
          ? getMenuItem(
              t("sidebar.my-reports"),
              RouteNames.REPORTS,
              <span>
                <IconMyReports />
              </span>,
              filteredChildMenus,
            )
          : getMenuItem(
              <>
                <span>
                  <IconMyReports />
                </span>
                {t("sidebar.my-reports")}
              </>,
              RouteNames.REPORTS,
              null,
              filteredChildMenus,
              "group",
              reportMenuRoutes.includes(pageRoute as RouteNames)
                ? "ant-menu-item-group menu-item menu-item-group-selected"
                : "ant-menu-item-group menu-item",
            ),
        [RouteNames.DEFINY]: sidebarCollapsed
          ? getMenuItem(
              t("sidebar.definy-pro-wallet"),
              RouteNames.DEFINY,
              <span>
                <IconDefinyProWallet />
              </span>,
              filteredChildMenus,
            )
          : getMenuItem(
              <>
                <span>
                  <IconDefinyProWallet />
                </span>
                {t("sidebar.definy-pro-wallet")}
              </>,
              RouteNames.DEFINY,
              null,
              filteredChildMenus,
              "group",
              "menu-item menu-item-group-highlight",
            ),
        [RouteNames.PLAYERS_CONTROL]: getMenuItem(
          t("sidebar.players-control"),
          "players-control",
          <span>
            <IconSidebarPlayers />
          </span>,
          filteredChildMenus,
        ),
        [RouteNames.CMS]: getMenuItem(
          t("sidebar.cms"),
          "cms",

          <PicLeftOutlined style={{ fontSize: "18px" }} />,
          filteredChildMenus,
        ),
        [RouteNames.MESSENGER]: getMenuItem(
          getLink(`/${RouteNames.MESSENGER}`, "sidebar.messenger"),
          RouteNames.MESSENGER,
          <span>
            <IconMessenger />
          </span>,
        ),
        [RouteNames.SYSTEM_USERS]: getMenuItem(
          t("sidebar.system-users"),
          RouteNames.SYSTEM_USERS,
          <span>
            <IconSystemUsers />
          </span>,
          filteredChildMenus,
        ),
        [RouteNames.BONUS_CONTROL]: getMenuItem(
          t("sidebar.bonus-control"),
          RouteNames.BONUS_CONTROL,
          <span>
            <IconBonusControl />
          </span>,
          filteredChildMenus,
        ),
        [RouteNames.TRANSLATIONS]: getMenuItem(
          t("sidebar.translations"),
          RouteNames.TRANSLATIONS,
          <span>
            <IconTranslations />
          </span>,
          filteredChildMenus,
        ),
        [RouteNames.GAMES_CONTROL]: getMenuItem(
          t("sidebar.games-control"),
          RouteNames.GAMES_CONTROL,
          <span>
            <IconGamesControl />
          </span>,
          filteredChildMenus,
        ),
        [RouteNames.ADMIN_CONTROL]: getMenuItem(
          t("sidebar.admin-control"),
          RouteNames.ADMIN_CONTROL,
          <span>
            <IconSettingsControl />
          </span>,
          filteredChildMenus,
        ),
      };

      return items[key as RouteNames];
    };

    return availableMenus.map((config: any) =>
      getItems(config.item, config?.excludedMenus),
    );
  }, [
    showCashier,
    showDeposit,
    showWithdrawal,
    showReferralReports,
    showCashbackReports,
    getLink,
    showReels,
    pageRoute,
    showFreeSpinCtrl,
    showSportBonusCtrl,
    availableMenus,
    sidebarCollapsed,
    t,
  ]);

  return (
    <Sider
      collapsible
      collapsed={sidebarCollapsed}
      trigger={null}
      id="left-sidebar"
    >
      <div className="header-brand-name">
        {!sidebarCollapsed && brand && (
          <span className="brand-name">{brand}</span>
        )}
        <Button
          className="toggle-sidebar-btn"
          onClick={handleButtonClick}
          icon={
            sidebarCollapsed ? (
              <MenuUnfoldOutlined style={ICON_STYLE} />
            ) : (
              <MenuFoldOutlined style={ICON_STYLE} />
            )
          }
        />
      </div>
      <Menu
        theme="dark"
        mode="inline"
        defaultOpenKeys={[RouteNames.REPORTS, categoryRoute]}
        defaultSelectedKeys={[categoryRoute, pageRoute]}
        selectedKeys={[categoryRoute, pageRoute]}
        id="sidebar-menu"
        items={menuItems}
      />
      {!sidebarCollapsed && (
        <div className="sidebar-app-version">
          {t("sidebar.version", { version })}
        </div>
      )}
    </Sider>
  );
};
